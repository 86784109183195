import { matchPath } from 'react-router-dom';


const allLinks = [

    '/',
    '/usluge',
    '/usluge/:alias',
    '/reference',
    '/reference/:alias',
    '/o-nama',
    '/blog',
    '/blog/:alias',
    '/kontakt',
    '/privacy-policy',
    '/terms-and-conditions'
]



const linkTranslates = {
    'ba': {
        '/': '/sr',
        '/usluge': '/sr/usluge',
        '/usluge/:alias': '/sr/usluge/:alias',
        '/reference': '/sr/reference',
        '/reference/:alias': '/sr/reference/:alias',
        '/o-nama': '/sr/o-nama',
        '/blog': '/sr/blog',
        '/blog': '/sr/blog/:alias',
        '/kontakt': '/sr/kontakt',
        '/privacy-policy': '/sr/privacy-policy',
        '/terms-and-conditions': '/sr/terms-and-conditions'

    },
    'sr': {
        '/': '/sr',
        '/usluge': '/sr/usluge',
        '/usluge/:alias': '/sr/usluge/:alias',
        '/reference': '/sr/reference',
        '/reference/:alias': '/sr/reference/:alias',
        '/o-nama': '/sr/o-nama',
        '/blog': '/sr/blog',
        '/blog/:alias': '/sr/blog/:alias',
        '/kontakt': '/sr/kontakt',
        '/privacy-policy': '/sr/privacy-policy',
        '/terms-and-conditions': '/sr/terms-and-conditions'
    },
    'en': {
        '/': '/en',
        '/usluge': '/en/services',
        '/usluge/:alias': '/en/services/:alias',
        '/reference': '/en/portfolio',
        '/reference/:alias': '/en/portfolio/:alias',
        '/o-nama': '/en/about-us',
        '/blog': '/en/blog',
        '/blog/:alias': '/en/blog/:alias',
        '/kontakt': '/en/contact',
        '/privacy-policy': '/en/privacy-policy',
        '/terms-and-conditions': '/en/terms-and-conditions'
    },
    'de': {
        '/': '/de',
        '/usluge': '/de/services',
        '/usluge/:alias': '/de/services/:alias',
        '/reference': '/de/portfolio',
        '/reference/:alias': '/de/portfolio/:alias',
        '/o-nama': '/de/uber-uns',
        '/blog': '/de/blog',
        '/blog/:alias': '/de/blog/:alias',
        '/kontakt': '/de/kontakt',
        '/privacy-policy': '/de/datenschutzerklarung',
        '/terms-and-conditions': '/de/arbeitsbedingungen'
    }
}

export default function (link, lang) {
    let to = link;
    if (!lang) {
        lang = 'ba';
    }

    if (lang !== 'ba') {
        allLinks.some(route => {

            const match = matchPath(to, route);
            if (match && match.isExact) {
                to = to.replace(route.split(':')[0], linkTranslates[lang][route].split(':')[0])
            }
            return match && match.isExact;
        });
    }


    return to;
}