import HomePage from './views/homePage';
import ServicesPage from './views/servicesPage';
import ServicesDetailPage from './views/servicesDetailPage';

import ProjectsPage from './views/projectsPage';
import ProjectsDetailPage from './views/projectsDetailPage';

import BlogPage from './views/blogPage';
import BlogDetailPage from './views/blogDetailPage';
import AboutUsPage from './views/aboutUsPage';
import ContactPage from './views/contactPage';
import PrivacyPolicyPage from './views/privacyPolicyPage';
import TermsAndConditionsPage from './views/termsAndConditionsPage';


const linkTranslates = {
    'sr': {
        '/' : '/sr',
        '/usluge': '/sr/usluge',
        '/usluge/:alias': '/sr/usluge/:alias',
        '/reference': '/sr/reference',
        '/reference/:alias': '/sr/reference/:alias',
        '/o-nama': '/sr/o-nama',
        '/blog': '/sr/blog',
        '/blog/:alias': '/sr/blog/:alias',
        '/kontakt': '/sr/kontakt',
        '/privacy-policy': '/sr/privacy-policy',
        '/terms-and-conditions': '/sr/terms-and-conditions'

    },
    'en': {
        '/' : '/en',
        '/usluge': '/en/services',
        '/usluge/:alias': '/en/services/:alias',
        '/reference': '/en/portfolio',
        '/reference/:alias': '/en/portfolio/:alias',
        '/o-nama': '/en/about-us',
        '/blog': '/en/blog',
        '/blog/:alias': '/en/blog/:alias',
        '/kontakt': '/en/contact',
        '/privacy-policy': '/en/privacy-policy',
        '/terms-and-conditions': '/en/terms-and-conditions'

    },
    'de': {
        '/' : '/de',
        '/usluge': '/de/services',
        '/usluge/:alias': '/de/services/:alias',
        '/reference': '/de/portfolio',
        '/reference/:alias': '/de/portfolio/:alias',
        '/o-nama': '/de/uber-uns',
        '/blog': '/de/blog',
        '/blog/:alias': '/de/blog/:alias',
        '/kontakt': '/de/kontakt',
        '/privacy-policy': '/de/datenschutzerklarung',
        '/terms-and-conditions': '/de/arbeitsbedingungen'

    },
}

export const routes = [
    {
        path: "/",
        component: HomePage,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction('https://api.novamedia.agency/home', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((data) => {
                    return {
                        data
                    }
                })

            },
            (fetchFunction, lang, match) => {
                return fetchFunction('https://api.novamedia.agency/projects/promoted', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        count: 4,
                        lang: lang
                    })
                }).then(res => res.json()).then((result) => {

                    return {
                        promotedProjects: result,
                    }
                })

            }
        ]

    },
    {
        path: "/privacy-policy",
        component: PrivacyPolicyPage,
        loadData: []

    },
    {
        path: "/terms-and-conditions",
        component: TermsAndConditionsPage,
        loadData: []

    },

    {
        path: "/usluge",
        component: ServicesPage,
        loadData: []

    },
    {
        path: "/usluge/:alias",
        component: ServicesDetailPage,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction('https://api.novamedia.agency/projects/promoted', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        count: 4,
                        service: match.params.alias,
                        lang: lang
                    })
                }).then(res => res.json()).then((result) => {
                    return {
                        promotedProjects: result,
                    }
                })

            }
        ]

    },
    {
        path: "/reference",
        component: ProjectsPage,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction('https://api.novamedia.agency/projects/categories', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((categories) => {
                    return {
                        categories
                    }
                })

            }
        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction('https://api.novamedia.agency/projects', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        category: query.category,
                        lang: lang,
                        page: query.page
                    })
                }).then(res => res.json()).then((result) => {

                    return {
                        page: query.page ? query.page : 0,
                        projects: result.items,
                        total: result.total
                    }
                })

            }

        ]

    },
    {
        path: "/reference/:alias",
        component: ProjectsDetailPage,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction('https://api.novamedia.agency/projects/detail/' + lang + '/' + match.params.alias, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((data) => {

                    return {
                        data
                    }
                })




            },
            (fetchFunction, lang, match) => {
                return fetchFunction('https://api.novamedia.agency/projects/promoted', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        count: 2,
                        project: match.params.alias,
                        lang: lang
                    })
                }).then(res => res.json()).then((result) => {

                    return {
                        promotedProjects: result
                    }
                })

            },
        ]

    },
    {
        path: "/blog",
        component: BlogPage,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction('https://api.novamedia.agency/blog/categories', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((categories) => {

                    return {
                        categories
                    }
                })

            },
            (fetchFunction, lang, match) => {

                return fetchFunction('https://api.novamedia.agency/blog/tags', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((tags) => {

                    return {
                        tags
                    }
                })

            },
        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction('https://api.novamedia.agency/blog', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        category: query.category,
                        tag: query.tag,
                        lang: lang
                    })
                }).then(res => res.json()).then((result) => {

                    return {
                        page: query.page ? query.page : 0,
                        items: result.items,
                        total: result.total
                    }
                })

            }
        ]

    },
    {
        path: "/blog/:alias",
        component: BlogDetailPage,
        loadData:
            [
                (fetchFunction, lang, match) => {
                    return fetchFunction('https://api.novamedia.agency/blog/latest/' + lang + '/' + match.params.alias, {
                        method: 'GET',
                        headers: {
                            'content-type': 'application/json'
                        }
                    }).then(res => res.json()).then((latestNews) => {
                        return {
                            latestNews
                        }
                    })
                },
                (fetchFunction, lang, match) => {
                    return fetchFunction('https://api.novamedia.agency/blog/detail/' + lang + '/' + match.params.alias, {
                        method: 'GET',
                        headers: {
                            'content-type': 'application/json'
                        }
                    }).then(res => res.json()).then((data) => {
                        return {
                            data
                        }
                    })
                }

            ]
    },
    {
        path: "/o-nama",
        component: AboutUsPage,
        loadData: [
            (fetchFunction, lang, match) => {

                return fetchFunction('https://api.novamedia.agency/aboutus', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((data) => {
                    return {
                        data
                    }
                })

            }
        ]

    },
    {
        path: "/kontakt",
        component: ContactPage,
        loadData: []

    },
];

export function generateRoutes(r) {
    let newRoutes = [];
    for(let i=0;i<r.length;i++){
        newRoutes.push(r[i]);

        newRoutes.push( {
            ...routes[i],
            path: linkTranslates.sr[r[i].path]
        })
        newRoutes.push( {
            ...r[i],
            path: linkTranslates.en[r[i].path]
        })
        newRoutes.push( {
            ...r[i],
            path: linkTranslates.de[r[i].path]
        })

    }
    return newRoutes;
}